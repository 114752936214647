import React from 'react'
import styled from 'styled-components'
// import tw from 'twin.macro'
import SwipeableViews from 'react-swipeable-views'
import { StepIndex } from './Stepper'

const StepNode = ({ onClick, ...rest }) => {
  const linkColor = rest.trailing ? 'bg-golden-100' : 'bg-primary-100'
  return (
    <>
      {!rest.leading && <div className={`h-px flex-1 ${linkColor}`}></div>}
      <button className="rounded-xl" onClick={onClick}>
        <StepIndex {...rest} />
      </button>
    </>
  )
}

// An invisible drag handler over the phone image.
const StepDetailsContainer = styled.div`
  & ::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80vh;
    opacity: 0;
  }
`

const StepDetails = ({ title, details, trailing }) => {
  const bgColor = trailing ? 'bg-golden-100' : 'bg-primary-100'
  return (
    <StepDetailsContainer className="px-1 sm:px-4 h-full">
      <div className={`rounded-xl px-5 pt-3 pb-4 shadow-sm h-full ${bgColor}`}>
        <div className="text-xl font-semibold">{title}</div>
        <div className="text-sm text-gray-600 leading-snug">{details}</div>
      </div>
    </StepDetailsContainer>
  )
}

export default ({ index, setIndex, STEPS_HOW }) => (
  <div className="-mt-2">
    <SwipeableViews
      axis="x"
      index={index}
      onChangeIndex={i => setIndex(i)}
      enableMouseEvents
      // autoplay={autoplay ? isAutoplayOn : false}
      // interval={interval}
      style={{
        padding: '0 1.2rem',
        overflowX: 'visible',
        marginBottom: '-.25rem',
      }}
      slideStyle={{ padding: '0 4px .25rem' }}
      className="sm:w-5/6 mx-auto"
    >
      {STEPS_HOW.map((item, idx) => (
        <StepDetails
          key={idx}
          {...item}
          trailing={idx === STEPS_HOW.length - 1}
        />
      ))}
    </SwipeableViews>
    <div className="mt-4 flex items-center justify-between m-auto w-5/6 sm:w-4/6">
      {STEPS_HOW.map((item, idx) => (
        <StepNode
          key={idx}
          step={idx + 1}
          leading={!idx}
          trailing={idx === STEPS_HOW.length - 1}
          onClick={() => setIndex(idx)}
          active={idx === index}
          SvgComp={item.SvgComp}
        />
      ))}
    </div>
  </div>
)
