import React from 'react'

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 25 20">
    <g>
      <path d="m21.875 0h-18.75c-1.72588984 0-3.125 1.39911016-3.125 3.125v13.3928571c0 1.7258899 1.39911016 3.125 3.125 3.125h18.75c1.7258898 0 3.125-1.3991101 3.125-3.125v-13.3928571c0-1.72588984-1.3991102-3.125-3.125-3.125zm-18.75 1.78571429h18.75c.7396671 0 1.3392857.59961863 1.3392857 1.33928571v13.3928571c0 .7396671-.5996186 1.3392858-1.3392857 1.3392858h-18.75c-.73966708 0-1.33928571-.5996187-1.33928571-1.3392858v-13.3928571c0-.73966708.59961863-1.33928571 1.33928571-1.33928571z" />
      <path d="m19.987553 3.75950695c.3892386-.30274111.9501989-.23262106 1.2529401.1566175.2987045.38404872.2344246.93527051-.1412183 1.24069369l-.0153993.01224634-8.0357142 6.25000002c-.3168611.2464475-.7583725.2506966-1.0795319.0127473l-.0167907-.0127473-8.03571425-6.25000002c-.38923856-.30274111-.45935861-.86370146-.1566175-1.25294003.29870456-.38404871.84878842-.45743201 1.23727992-.16852841l.01566011.01191091 7.48749722 5.82358457z" />
    </g>
  </svg>
)
