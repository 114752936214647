// import React from 'react'
import digits from '../svg/RoundedDigits'
import Star from '../svg/Star'

export const STEPS_HOW = [
  {
    title: 'Выбирай заведение',
    SvgComp: digits[1],
    details: 'По близости, цене, с расписанием и фитнес-услугами на свой вкус.',
  },
  {
    title: 'Начинай тренировку',
    details: 'Сканируй QR-код на стойке администратора заведения.',
    SvgComp: digits[2],
  },
  {
    title: 'Тренируйся',
    details: 'Пользуйся услугами, контролируй время визита через приложение.',
    SvgComp: digits[3],
  },
  {
    title: 'Завершай тренировку',
    details: 'Сканируй QR-код на выходе и плати только за минуты визита.',
    SvgComp: digits[4],
  },
  {
    title: 'Зови друзей!',
    details: 'И получай бонусные минуты для бесплатных визитов.',
    SvgComp: Star,
  },
]
