import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
// import AnchorLink from 'react-anchor-link-smooth-scroll'
// import { Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import Button from '../components/Button'
import TrendingUpFill from '../svg/TrendingUpFill'
import HeroPhoneImage from '../components/HeroPhoneImage'
import SectionHow from '../components/SectionHow'
import { Section } from '../components/layout'
import ThesesGrid from '../components/ThesesGrid'
import { THESES_B2C } from '../data/theses-why'
// import SectionGetApp from '../components/SectionGetApp'
import EmailForm from '../components/EmailForm'
import { LinkForPartners } from './partner'
import { useDeviceDetector, PartnerLogosSection } from '../components'

export default ({ data }) => {
  const [isDemoCritical, setIsDemoCritical] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 640) {
      setIsDemoCritical(true)
    }
  }, [])

  const { isReady } = useDeviceDetector()

  return (
    <Layout main>
      <SEO
      // title="Моментальный доступ к фитнес-услугам"
      />
      <Hero
        dark
        // title={`Ходи на фитнес.\nПлати поминутно.`}
        title={
          <div>
            {/* <div className="text-base mb-2 font-medium text-golden-900">
              Запуск в начале 2021
            </div> */}
            <div>{`Ходи на фитнес.\nПлати поминутно.`}</div>
          </div>
        }
        // subtitle="Мобильное приложение для моментального доступа в клубы, залы, бассейны и спортивные заведения."
        subtitle="Фитнес-клубы города в приложении с поминутной оплатой"
        namesL="sm:w-3/5 text-4xl sm:text-5xl xl:text-6xl"
        renderButtons={() =>
          !isReady ? null : (
            <div>
              <span role="img" aria-label="See you..">
                👋🏻
              </span>
            </div>
          )
        }
      >
        <div className="hidden sm:block w-2/5">
          <HeroPhoneImage
            file={data.file}
            onLoad={() => setIsDemoCritical(true)}
          />
        </div>
      </Hero>
      <div className="my-8">
        <Section id="partners" title="Партнеры">
          <PartnerLogosSection />
        </Section>
      </div>
      <div className="mt-24 mb-32">
        <SectionHow
          id="how"
          eyebrow="Как это работает"
          eyebrowColorNames="text-myindigo-900"
          title="Все клубы в одном приложении"
          {...{ isDemoCritical }}
        />
      </div>

      <div className="mt-12 mb-32">
        <Section
          id="why"
          eyebrow="Почему Octopass?"
          eyebrowColorNames="text-myindigo-900"
          title="Умная фитнес-карта"
          subtitle="Поминутная оплата фитнес-услуг в заведениях города. Для доступа нужен только смартфон."
        >
          <ThesesGrid
            items={THESES_B2C}
            className="md:grid-cols-3"
            iconColorNames="bg-myindigo-100 text-myindigo-900"
          />
        </Section>
      </div>

      {/* <div className="bg-darkbg-900 py-16"> */}
      <div className="bg-darkbg-900 pb-16" id="letmeknow">
        <div className="max-w-screen-lg m-auto px-6 md:px-6 ">
          {/* <SectionGetApp
            title="Установить"
            subtitle="Приложение с моментальным доступом к фитнес-услугам"
          /> */}
          <div className="border-t border-btngray-900 opacity-25 my-16"></div>
          <div className="flex justify-between flex-wrap">
            <LinkForPartners className="w-full sm:w-2/6">
              <Button
                Icon={TrendingUpFill}
                iconStyle={{ height: `1.5rem`, marginRight: `-1rem` }}
                title="Стать партнёром"
                color="text-gray-200"
                bgColor="bg-btngray-900"
                bgOpacity={0.11}
                className=" mb-4 sm:mr-6"
                // style={{ paddingTop: '1.22rem', paddingBottom: '1.22rem' }}
              />
            </LinkForPartners>
            <div className="flex-1 flex justify-end sm:ml-5">
              <EmailForm
                color="text-gray-200"
                placeHolderColor="placeholder-gray-200"
                bgColor="bg-btngray-900"
                bgOpacity={0.11}
                borderColor="focus:border-primary-900"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "hero-b2c-screen.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
