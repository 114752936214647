import React from 'react'

export default props => (
  <svg {...props} viewBox="0 0 352 288" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m307.958773-10.5360737c5.818913-6.6501861 15.927115-7.3240663 22.577301-1.5051534 6.559087 5.73920169 7.304482 15.65098258 1.740633 22.3020787l-.23548.2752221-224 256.0000003c-6.016603 6.876117-16.5363611 7.311974-23.1011829 1.025946l-.2537526-.248312-96-96c-6.2483887-6.248388-6.2483887-16.379028 0-22.627416 6.16160549-6.161606 16.09837056-6.247184 22.3648416-.256734l.2625754.256734 83.9062915 83.906708z"
      transform="translate(16 16)"
    />
  </svg>
)
