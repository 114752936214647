import React, { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import MailOutline from '../svg/MailOutline'
import ArrowForwardOutline from '../svg/ArrowForwardOutline'
import Checkmark from '../svg/Checkmark'
import Spinner from '../svg/Spinner'
import Inp from './Input'
import { useRecaptchaFirestoreSubmit } from '../helpers'

const Input = styled(Inp)`
  ${tw`pr-12`}
`

export default props => {
  const [email, setEmail] = useState('')
  const {
    isLoading,
    isSuccess,
    resetSuccess,
    handleSubmit,
  } = useRecaptchaFirestoreSubmit({ action: 'email_subscription' })

  function handleChange(e) {
    setEmail(e.target.value)
    if (isSuccess) resetSuccess()
  }
  function onSubmit(e) {
    e.preventDefault()
    handleSubmit({ email })
  }

  // const [placeholder, setPlaceholder] = useState(
  //   'Email-новости и бонусы. Подписаться'
  // )
  // React.useLayoutEffect(() => {
  //   if (typeof window !== `undefined`) {
  //     if (window.innerWidth < 410) setPlaceholder('Email-новости. Подписаться')
  //   }
  // }, [])

  const Icon = () =>
    isSuccess ? (
      <Checkmark className="fill-current h-5 text-mygreen-500" />
    ) : isLoading ? (
      <Spinner className="fill-current h-5 text-primary-900" />
    ) : email ? (
      <ArrowForwardOutline className="fill-current h-5 text-primary-900" />
    ) : (
      <MailOutline className="fill-current h-5" />
    )

  return (
    <div className="sm:max-w-sm w-full">
      <form className="flex relative" onSubmit={onSubmit}>
        <Input
          {...props}
          type="email"
          // placeholder={placeholder}
          placeholder="Email-новости. Подписаться"
          id="email-input"
          value={email}
          onChange={handleChange}
          required
          disabled={isLoading}
        />
        <button
          disabled={isSuccess || isLoading}
          className={`absolute right-0 h-full flex items-center p-4 rounded-xl z-20 focus:outline-none active:opacity-75 ${props.color}`}
        >
          <Icon />
        </button>
      </form>
    </div>
  )
}
