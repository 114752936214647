import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { motion, AnimatePresence } from 'framer-motion'

export const StepIndex = ({ SvgComp, active, trailing }) => {
  const colors = active
    ? {
        text: 'text-gray-100',
        bg: trailing ? 'bg-golden-900' : 'bg-primary-900'
      }
    : {
        text: trailing ? 'text-golden-900' : 'text-primary-900',
        bg: trailing ? 'bg-golden-100' : 'bg-primary-100'
      }
  return (
    <div className={`relative p-3 rounded-full overflow-hidden ${colors.text}`}>
      <div className={`absolute inset-0 ${colors.bg}`}></div>
      <SvgComp className="h-4 sm:h-5 relative fill-current" />
    </div>
  )
}

const NodeLink = styled.div`
  ${tw`w-px h-full`}
`

const StepNode = ({ leading, second2last, ...rest }) => {
  const linkColor = {
    top: rest.trailing ? 'bg-golden-100' : 'bg-primary-100',
    bottom: second2last && !rest.active ? 'bg-golden-100' : 'bg-primary-100'
  }
  return (
    <div>
      <div className="h-3 flex justify-center">
        {!leading && <NodeLink className={linkColor.top} />}
      </div>
      <StepIndex {...rest} />
      <div className="h-full flex justify-center">
        {!rest.trailing && <NodeLink className={linkColor.bottom} />}
      </div>
    </div>
  )
}

const Button = styled.button`
  &:focus {
    outline: none;
  }
  &:hover {
    & p:last-child {
      ${p => (p.active ? `` : tw`text-gray-700`)}
    }
  }
`

export default ({ index, setIndex, STEPS_HOW }) => {
  const p = {
    anim: {
      opacity: 0,
      scale: 0.96,
      transition: { duration: 0.2, ease: 'easeIn' }
    },
    active: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: 'easeInOut'
      }
    }
  }
  return (
    <div>
      {STEPS_HOW.map((item, idx) => (
        <Button
          key={idx}
          className="text-left pl-3 pr-8 pb-3 flex items-stretch relative"
          onClick={() => setIndex(idx)}
          active={idx === index}
        >
          <AnimatePresence>
            <motion.div
              className="absolute inset-0"
              key={index}
              initial={p.anim}
              animate={p.active}
              exit={p.anim}
              style={{ zIndex: 0 }}
            >
              {idx === index && (
                <div
                  className={`rounded-xl h-full ${
                    idx < STEPS_HOW.length - 1
                      ? 'bg-primary-100'
                      : 'bg-golden-100'
                  }`}
                ></div>
              )}
            </motion.div>
          </AnimatePresence>
          <StepNode
            leading={!idx}
            second2last={idx === STEPS_HOW.length - 2}
            trailing={idx === STEPS_HOW.length - 1}
            {...item}
            active={idx === index}
          />

          <div className="pl-4 pt-4 z-10">
            <p className="text-xl leading-6 font-bold">{item.title}</p>
            <p
              className={`mt-1 leading-tight text-gray-500 transition duration-200 ease-out${
                idx === index ? ` text-gray-700` : ``
              }`}
            >
              {item.details}
            </p>
          </div>
        </Button>
      ))}
    </div>
  )
}
