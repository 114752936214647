import React, { useRef, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { motion, AnimatePresence } from 'framer-motion'
import { BgImageContained } from './BgImageContained'

const Container = styled.div`
  padding-bottom: 64px; /* For the shadow below. */
  height: 570px;
  @media (max-width: 1024px) {
    max-width: 330px;
  }
  @media (max-width: 500px) {
    max-height: 64vh;
  }
`
const MultiBackground = styled(BgImageContained)`
  height: 100%;
  /* background-size: contain; */
  background-size: contain, contain, 0, 0, 0, 0, 0;
  transform: rotateZ(-8deg);
`

const PhoneAppDemo = ({ index = 0, STEPS_HOW, isDemoCritical }) => {
  const { demo } = useStaticQuery(
    graphql`
      query {
        demo: allFile(
          filter: { relativePath: { regex: "/demo.+.png/" } }
          sort: { fields: relativePath }
        ) {
          files: edges {
            node {
              childImageSharp {
                fluid(maxWidth: 400, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )

  const [shadow, body, ...rest] = useRef(
    demo.files.map(item => item.node)
  ).current
  const screen = rest[index]
  const [isCached, setIsCached] = React.useState()

  // function handleOnLoad() {
  //   if (!isCached) setIsCached(true)
  // }

  // The rest in back for preloading/caching.
  const stack = [screen, body, ...(isCached ? [] : rest)].map(
    item => item.childImageSharp.fluid
  )
  const p = {
    anim: {
      opacity: 0,
      scale: 0.99,
      translateX: '-2px',
      transition: { duration: 0.2, ease: 'easeOut' }
    },
    active: {
      opacity: 1,
      scale: 1,
      translateX: 0,
      transition: {
        duration: 0.4,
        ease: 'easeInOut'
      }
    }
  }

  // console.log('isDemoCritical', isDemoCritical)
  const MultiBg = useCallback(
    () => (
      <MultiBackground
        Tag="div"
        fluid={stack}
        critical={isDemoCritical && !isCached}
        // critical
        onLoad={() => !isCached && setIsCached(true)}
      />
    ),
    [isDemoCritical, isCached, stack]
  )

  return (
    <Container className="m-auto overflow-hidden">
      <AnimatePresence exitBeforeEnter>
        <motion.div
          className="h-full"
          key={index}
          initial={p.anim}
          animate={p.active}
          exit={p.anim}
        >
          <MultiBg />
          <Image
            className="w-4/6 mx-auto opacity-75 mt-2"
            fluid={shadow.childImageSharp.fluid}
          />
        </motion.div>
      </AnimatePresence>
    </Container>
  )
}

export default PhoneAppDemo
