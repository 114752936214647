import React, { useState } from 'react'
import { STEPS_HOW } from '../data/steps-how'
import { Section } from './layout'
import PhoneAppDemo from './PhoneAppDemo'
import Stepper from './Stepper'
import StepperMobile from './StepperMobile'

const SectionHow = ({ isDemoCritical, ...headerProps }) => {
  const [index, setIndex] = useState(0)

  return (
    <Section {...headerProps}>
      <div className="mt-16 sm:flex">
        <div className="sm:w-1/2 md:w-2/5 -ml-8">
          <PhoneAppDemo {...{ index, STEPS_HOW, isDemoCritical }} />
        </div>
        <div className="hidden sm:block flex-1 md:ml-20 md:mr-5">
          <Stepper {...{ index, setIndex, STEPS_HOW }} />
        </div>
        <div className="sm:hidden -mx-6 sm:-mx-6 lg:-mx-8">
          <StepperMobile {...{ index, setIndex, STEPS_HOW }} />
        </div>
      </div>
    </Section>
  )
}

export default SectionHow
