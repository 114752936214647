import React from 'react'

const Dig0 = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 18 18">
    <path d="m9.32714844 17.1992188c3.93164066 0 6.31640626-3.1259766 6.31640626-8.13183599 0-5.00585937-2.40625-8.06738281-6.31640626-8.06738281-3.91015625 0-6.32714844 3.07226562-6.32714844 8.078125 0 5.0166016 2.39550781 8.1210938 6.32714844 8.1210938zm0-2.5996094c-1.91210938 0-3.02929688-1.9658203-3.02929688-5.5214844 0-3.5234375 1.13867188-5.47851562 3.02929688-5.47851562 1.90136716 0 3.01855466 1.94433593 3.01855466 5.47851562 0 3.5556641-1.1064453 5.5214844-3.01855466 5.5214844z" />
  </svg>
)

const Dig1 = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 18 18">
    <path d="m10.9404297 16.9628906c.9560547 0 1.6005859-.6445312 1.6005859-1.6220703v-12.52539061c0-1.1171875-.6875-1.81542969-1.836914-1.81542969-.6660157 0-1.18164066.09667969-1.93359379.61230469l-3.07226562 2.12695312c-.50488281.35449219-.69824219.71972657-.69824219 1.21386719 0 .6875.48339844 1.16015625 1.13867188 1.16015625.33300781 0 .55859374-.07519531.859375-.29003906l2.26660156-1.57910157h.06445312v11.09667968c0 .9775391.65527344 1.6220703 1.61132814 1.6220703z" />
  </svg>
)

const Dig2 = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 18 18">
    <path d="m13.1083984 16.8447266c.8378907 0 1.3427735-.5263672 1.3427735-1.2890625 0-.7841797-.5048828-1.2890625-1.3427735-1.2890625h-5.84374996v-.0644532l3.35156246-3.2226562c2.5351563-2.39550782 3.4589844-3.57714845 3.4589844-5.46777345 0-2.6640625-2.2128906-4.51171875-5.49999999-4.51171875-2.93261719 0-4.87695312 1.60058594-5.40332031 3.31933594-.07519531.22558594-.11816406.45117187-.11816406.69824218 0 .80566407.515625 1.32128907 1.38574218 1.32128907.69824219 0 1.07421876-.30078125 1.43945313-.9453125.55859375-1.24609375 1.41796875-1.86914063 2.6640625-1.86914063 1.38574219 0 2.37402345.91308594 2.37402345 2.19140625 0 1.1171875-.4941406 1.86914063-2.40625001 3.70605469l-4.61914063 4.4150391c-.65527344.6015625-.89160156 1.0097656-.89160156 1.6220703 0 .8164062.50488281 1.3857422 1.52539062 1.3857422z" />
  </svg>
)

const Dig3 = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 18 18">
    <path d="m8.74707031 17.1884766c3.59863279 0 6.03710939-1.8691407 6.03710939-4.6835938 0-2.1376953-1.4931641-3.49121092-3.8027344-3.70605468v-.06445312c1.8261719-.37597656 3.2333985-1.66503906 3.2333985-3.63085938 0-2.48144531-2.2128907-4.10351562-5.48925786-4.10351562-2.73925782 0-4.50097656 1.26757812-5.18847656 2.78222656-.16113282.36523438-.23632813.67675782-.23632813 1.04199219 0 .78417969.46191406 1.31054687 1.36425781 1.31054687.73046875 0 1.10644532-.26855468 1.4609375-.99902343.50488282-1.09570313 1.29980469-1.64355469 2.61035156-1.64355469 1.56835938 0 2.40624998.82714844 2.40624998 2.07324219 0 1.26757812-1.0527343 2.1484375-2.67480466 2.1484375h-.63378906c-.78417969 0-1.23535157.47265625-1.23535157 1.14941406 0 .69824219.45117188 1.16015625 1.23535157 1.16015625h.67675781c1.87988281 0 2.98632811.8378906 2.97558591 2.3417969 0 1.2890625-1.0957031 2.2128906-2.68554685 2.2128906-1.61132813 0-2.44921875-.6445312-2.99707031-1.7294922-.32226563-.5908203-.73046875-.8486328-1.375-.8486328-.89160156 0-1.42871094.5263672-1.42871094 1.3642578 0 .3222656.07519531.6660156.24707031 1.0205078.73046875 1.5253906 2.54589844 2.803711 5.5 2.803711z" />
  </svg>
)

const Dig4 = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 18 18">
    <path d="m11.2705078 16.9628906c.8916016 0 1.546875-.5478515 1.546875-1.5791015v-1.4931641h.8808594c.8486328 0 1.3320312-.5263672 1.3320312-1.2890625s-.4726562-1.2998047-1.3320312-1.2998047h-.8808594v-7.95996092c0-1.45019532-1.03125-2.34179688-2.6748047-2.34179688-1.31054685 0-2.09472654.53710938-3.16894529 2.18066406-1.68652343 2.50292969-3.23339843 4.86621094-4.296875 6.77832032-.48339843.84863282-.67675781 1.45019532-.67675781 2.11621092 0 1.0849609.78417969 1.8154297 1.95507812 1.8154297h5.77929688v1.4931641c0 1.03125.6445312 1.5791015 1.5361328 1.5791015zm-1.47167968-5.5751953h-4.96289062v-.0859375c1.25683594-2.34179686 2.68554688-4.52246092 4.8984375-7.71289061h.06445312z" />
  </svg>
)

const Dig5 = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 18 18">
    <path d="m8.63964844 16.8447266c3.53417966 0 5.99414066-2.234375 5.99414066-5.5429688 0-2.95410155-2.1054688-5.03808592-5.07031254-5.03808592-1.57910156 0-2.84667968.62304687-3.48046875 1.62207031h-.06445312l.36523437-4.30761719h6.12304684c.8164063 0 1.3320313-.50488281 1.3320313-1.2890625s-.5263672-1.2890625-1.3320313-1.2890625h-6.79980465c-1.25683594 0-1.93359375.52636719-2.04101563 1.83691406l-.45117187 5.68261719c-.01074219.05371094-.01074219.0859375-.01074219.12890625-.04296875.87011719.46191406 1.6113281 1.52539063 1.6113281.71972656 0 1.04199219-.1611328 1.67578125-.7519531.54785156-.52636719 1.32128906-.92382812 2.24511718-.92382812 1.68652348 0 2.88964848 1.16015624 2.88964848 2.79296872 0 1.6757813-1.2138672 2.8896485-2.90039066 2.8896485-1.39648438 0-2.30957032-.6767578-2.86816406-1.7939454-.36523438-.5800781-.7734375-.805664-1.41796876-.805664-.83789062 0-1.35351562.4941406-1.35351562 1.321289 0 .3867188.09667969.7197266.2578125 1.0634766.7734375 1.6435547 2.921875 2.7929688 5.38183594 2.7929688z" />
  </svg>
)

export default [Dig0, Dig1, Dig2, Dig3, Dig4, Dig5]
