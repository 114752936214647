import React from 'react'

export default props => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m12 0c6.627417 0 12 5.372583 12 12s-5.372583 12-12 12-12-5.372583-12-12 5.372583-12 12-12zm7.1611563 7-4.2897602.00016869c-.482846.00931994-.8713961.4036447-.8713961.8887202l.0001687.01749281c.0093199.48284602.4036447.87139608.8887202.87139608l2.0071111-.00077778-3.9336164 3.7803957-.0158181.0147043c-.159944.1449204-.3722927.2259815-.5934083.2259815-.2284862 0-.4476113-.0865553-.6091569-.2406195l-.8608395-.757538-.0232047-.0218528c-.4822389-.4484514-1.12961829-.6999394-1.80419645-.6999394-.68545845 0-1.34283368.2596659-1.82747066.7218585l-3.60903107 3.0936376-.01382157.0134783c-.3225032.3216658-.31789601.8317324.01382157 1.1480153.33638966.3207376.88178505.3207376 1.21817471 0l3.60910061-3.0937039.01581809-.0147043c.15994394-.1449203.3722927-.2259815.59340832-.2259815.22848615 0 .44761123.0865553.60915689.2406195l.86083946.7575381.0232047.0218527c.4822389.4484515 1.1296183.6999395 1.8041965.6999395.6854584 0 1.3428336-.259666 1.8274706-.7218585l4.0913722-3.9308231.0004361 2.3406039c.00932.482846.4036447.8713961.8887202.8713961.4909198 0 .8888889-.3979691.8888889-.8888889v-4.22222221l-.0001687-.01749282c-.0093199-.48284601-.4036447-.87139607-.8887202-.87139607z"
      fillRule="evenodd"
    />
  </svg>
)
