import React from 'react'

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 25 24">
    <g>
      <path d="m12.154822.48815537c.6441638-.64416379 1.6844335-.65080465 2.3367645-.0199226l.0202581.0199226 10 10.00000003c.6441638.6441637.6508047 1.6844335.0199226 2.3367644l-.0199226.0202582-10 10c-.6508738.6508738-1.7061487.6508738-2.3570226 0-.6441637-.6441638-.6508046-1.6844335-.0199226-2.3367645l.0199226-.0202581 8.8214974-8.8214887-8.8214974-8.82148873c-.6441637-.64416378-.6508046-1.68443352-.0199226-2.33676443z" />
      <path d="m21.9444444 10c.9204746 0 1.6666667.7461921 1.6666667 1.6666667 0 .9112698-.7313429 1.6517254-1.6391053 1.6664433l-.0275614.0002233h-20.27777773c-.92047459 0-1.66666667-.7461921-1.66666667-1.6666666 0-.9112699.73134286-1.6517254 1.63910529-1.6664434l.02756138-.0002233z" />
    </g>
  </svg>
)
