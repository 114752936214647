import React from 'react'
import styled from 'styled-components'
import { BgImageContained } from './BgImageContained'

const Container = styled.div`
  height: 550px;
  max-width: 330px;
  padding: 2rem 2rem 1rem 3rem;
  @media (max-width: 500px) {
    max-height: 64vh;
  }
`
const BgImage = styled(BgImageContained)`
  height: 100%;
  transform: rotateZ(10deg);
  background-size: contain;
`

export default ({ file, ...rest }) => {
  return (
    <Container className="ml-auto overflow-hidden">
      <BgImage Tag="div" fluid={file.childImageSharp.fluid} {...rest} />
    </Container>
  )
}
